import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconChevronRight(props: IconProps) {
	return (
		<Icon {...props}>
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="m9 5l7 7l-7 7"
			/>
		</Icon>
	);
}
